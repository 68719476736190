import React, { Component, ErrorInfo, ReactNode } from "react";
import { HiRefresh } from "react-icons/hi";
import { RiAlertFill } from "react-icons/ri";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    public static getDerivedStateFromError(): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        throw new Error(`"Uncaught error: " ${error} ${errorInfo}`);
    }

    public render() {
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            return (
                <main className="flex justify-center items-center min-h-screen bg-gray-50">
                    <div className="text-center space-y-6 p-8 max-w-md">
                        <RiAlertFill
                            className="mx-auto h-24 w-24 text-red-500"
                            aria-hidden="true"
                        />
                        <h1 className="text-3xl font-bold text-gray-900">
                            Something went wrong
                        </h1>
                        <p className="text-gray-600">
                            We apologize for the inconvenience. Please try
                            reloading the page.
                        </p>
                        <button
                            type="button"
                            onClick={() => window.location.reload()}
                            className="inline-flex items-center px-4 py-2 text-sm font-medium bg-slate-100   transition-colors"
                        >
                            <HiRefresh className="mr-2 h-4 w-4" />
                            Reload Page
                        </button>
                    </div>
                </main>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
